<template>
<v-app id="inspire">
    <div class="px-4">
        <loading :is-show="loadingDialog" :is-done="loadingFinished" :message="loadingMessage" v-on:close-dialog="closeDialog" />
        <v-container class="container--fluid grid-list-md text-center">
            <h1>Credit Accounts</h1>
        </v-container>
        <v-card elevation="2" outlined shaped tile class="pa-1">
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout row wrap>
                            <v-flex xs12>
                                <v-text-field label="Credit Account ID" v-model="query.code" single-line outlined @change="search($event, 'code')"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout wrap>
                            <v-flex xs12 sm6>
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color=success @click="queryData(true)">
                                    <v-icon dark left> mdi-magnify</v-icon> Search
                                </v-btn>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color="success" @click.stop="showCreditForm('CREATED')">Create Credit</v-btn>
                                <credit-form :selected-item="selectedCredit" :is-show="showFormDialog" :form-type="creditFormType" v-on:close-dialog="closeDialog" v-on:save-credit="saveCredit" />
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
        </v-card>

        <v-data-table :hide-default-header="true" :headers="headers" :items="displayCredits" :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalCredits" :footer-props="{'items-per-page-options': [5]}" class="elevation-2 mt-4 px-4">
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr :colspan="headers.length">
                        <th v-for="header in headers" :key="header.text">
                            <span class="d-flex justify-center">{{header.text}}</span>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                        <td> <span class="d-flex justify-center"> {{ item.id }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.name }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.dateCreated }} </span></td>
                        <td>
                            <span class="d-flex justify-center">
                                <v-btn fab dark x-small color="green" @click.stop="showCreditForm('UPDATE', item)">
                                    <v-icon dark medium>mdi-pencil</v-icon>
                                </v-btn>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Found no results.
                </v-alert>
            </template>
        </v-data-table>
    </div>
</v-app>
</template>

<script>
import Loading from '@/views/components/Loading.vue'
import CreditForm from '@/views/components/CreditAccountForm.vue'
import {
    mapGetters
} from 'vuex';

export default {
    name: 'Credits',
    components: {
        Loading,
        CreditForm
    },
    data() {
        return {
            //filter/search variables
            filterQry: {
                qryName: "",
            },
            headers: [],
            displayCredits: [],
            remainderCredits: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            creditFormType: 'CREATE',
            selectedCredit: {},
            paymentMethods: [],
            page: 1,
            totalCredits: 0,
            numberOfPages: 0,
            options: {
                rowsPerPage: 5
            },
            details: {
                title: '',
                item: {}
            },
            query: {
                code: '',
                name: ''
            },
            isShowDetails: false,
            showFormDialog: false,
        }
    },
    watch: {
        options: {
            handler() {
                console.log('1')
                this.queryData()
            },
        },
        deep: true,
    },
    computed: {
        //get role attributes
        ...mapGetters([
            'attributes',
            'credit'
        ]),
    },
    async mounted() {
        this.setHeaders()
        //this.queryData()
    },
    methods: {
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                const limit = itemsPerPage + 1
                //const creditListResponse = await API.getMerchants(pageNumber, limit, this.query)
                const creditListResponse = {
                    credits: [{
                        id: "1001",
                        name: "test1",
                        dateCreated: '2020-11-06 15:50:52.0'
                    }, {
                        id: "1002",
                        name: "test2",
                        dateCreated: '2020-11-06 15:50:52.0'
                    }, {
                        id: "1003",
                        name: "test3",
                        dateCreated: '2020-11-06 15:50:52.0'
                    }, {
                        id: "1004",
                        name: "test4",
                        dateCreated: '2020-11-06 15:50:52.0'
                    }]
                }
                if (!creditListResponse || creditListResponse.error) {
                    //error getting data
                    console.log(`${creditListResponse.error}`)
                } else {
                    var filteredList = creditListResponse.credits
                    if (page > 1 && this.remainderCredits.length > 0) {
                        filteredList = this.remainderCredits.concat(filteredList)
                    }
                    this.totalCredits = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayCredits = filteredList.slice(0, end)
                    this.remainderCredits = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = this.totalCredits / itemsPerPage
                }
                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        },
        setHeaders() {
            var headers = [{
                    text: 'Credit Account ID',
                    value: 'id'
                },
                {
                    text: 'Credit Account Name',
                    value: 'name'
                },
                {
                    text: 'Date Created',
                    value: 'dateCreated'
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false
                }
            ]
            this.headers = headers
        },
        showCreditForm(what, item = {}) {
            this.creditFormType = what
            this.selectedCredit = item
            this.showFormDialog = true
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.$router.go(0);
            }
        },
        async saveCredit(value) {
            console.log(value)
            this.loadingDialog = true
            this.showFormDialog = false
            if (value) {
                if (value.formType === 'CREATED') {
                    this.loadingMessage = `Creating new credit <strong><i>${value.creditName}</i></strong>`
                    const createResponse = {} //await API.createMerchant(value)
                    this.loadingFinished = true
                    if (!createResponse || createResponse.error) {
                        this.loadingMessage = createResponse.error
                    } else {
                        this.loadingMessage = `Successfully created credit <strong><i>${value.creditName}</i></strong>`
                    }
                } else if (value.formType === 'UPDATE') {
                    this.loadingMessage = `Updating credit <strong><i>${value.creditName}</i></strong>`
                    const updateResponse = {} //await API.updateMerchant(value)
                    this.loadingFinished = true
                    if (!updateResponse || updateResponse.error) {
                        this.loadingMessage = updateResponse.error
                    } else {
                        this.loadingMessage = `Successfully updated credit <strong><i>${value.creditName}</i></strong>`
                    }
                } else {
                    this.loadingMessage = "Invalid command"
                    this.loadingFinished = true
                }
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        search(event, type) {
            this.query[type] = event
        }
    }
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%;
    margin-bottom: 50px;
}
</style>
